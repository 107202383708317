import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { Constants } from './models/constants';
import { AuthGuardService } from './services/auth/auth-guard.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  href: string;
  displayMenu = true;
  showMenu = false;
  innerPage = false;

  constructor(private auth: AuthGuardService, private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.href = '/' + event.url.split('?')[0].split('/')[1];
        // console.log(this.href);
        if (Constants.NOMENUPAGES.includes(this.href)) {
          this.displayMenu = false;
        } else {
          this.displayMenu = true;
        }

        if (Constants.INNERPAGES.includes(this.href)) {
          this.innerPage = true;
        } else {
          this.innerPage = false;
        }

        if (this.showMenu) {
          this.toggleMenu();
        }
      }
    });
  }

  ionViewDidEnter() {
    const user = this.auth.getCurrentBusinessUser();
    if (!user || !user.businessId || !user.token) {
      this.router.navigate(['login']);
    }
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  back() {
    const path = Constants.BACKMAP[this.href];
    this.router.navigate([path]);
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['login']);
  }
  
  billing() {
    this.router.navigate(['current-billing-details']);
  }
}
