import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Businessuser } from 'src/app/models/businessuser';
import { SubscriptionMap } from 'src/app/models/subscription-map';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  user: Businessuser = null;
  // subscription:SubscriptionMap =null;
  subscription: SubscriptionMap;
  count: number;


  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // console.log(
    //   'Entering activated route :::::::::::::::::::::>>>>>>>>>>>>>>>>'
    // );
    try {
      this.user = JSON.parse(localStorage.getItem('businessUser'));
      if (!this.user || !this.user.businessId || !this.user.token) {
        this.router.navigate(['login']);
        return false;
      }
      return true;
    } catch (error) {
      console.log('returning True from server side');
      //this.router.navigate(['home']);
      return true;
    }
  }

  getCurrentBusinessUser(): Businessuser {
    this.user = JSON.parse(localStorage.getItem('businessUser'));
    return this.user;
  }


  getCurrentBusinessSubscription(): SubscriptionMap {
    this.subscription = JSON.parse(localStorage.getItem('subscription'));
    return this.subscription;
  }

  getCurrentBusinessBranchCount(): any {
    this.count = JSON.parse(localStorage.getItem('branchCount'));
    return this.count;
  }
}
